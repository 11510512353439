import { CoreAPi } from "@/utils/core-api";
import { API_ENDPOINTS } from "@/utils/endpoints";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const ContactService = new CoreAPi(API_ENDPOINTS.CONTACT);

export const useContactMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) =>
      ContactService.findAllPost(API_ENDPOINTS.ENQUIRY_CREATE, data),
    // ContactService.findAllPost(API_ENDPOINTS.CONTACT, data),
    onSuccess(v: any) {

    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });
};
// export const useCreateEnquiryMutation = () => {
//   const queryClient = useQueryClient();

//   return useMutation(
//     (data: any) =>
//       ContactService.findAllPost(API_ENDPOINTS.CRM.ENQUIRY_CREATE, data),
//     {
//       onSuccess: (response: any) => {
//         if (response.status === 200) {
//           toast.success("Enquiry Created Successfully!");
//         } else {
//           toast.error("An error occurred while creating the chemist.");
//         }
//       },
//       onError: (error: any) => {
//         if (
//           error?.response &&
//           error?.response?.data &&
//           error?.response?.data?.message
//         ) {
//           toast.error(error?.response?.data?.message);
//         } else {
//           toast.error(`An error occurred `);
//         }
//       },
//       onSettled: (data, error, response) => {
//         // queryClient.invalidateQueries(API_ENDPOINTS.CRM.CHEMIST_CREATE);
//       },
//     }
//   );
// };

export const useBookDemoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) =>
      ContactService.findAllPost(API_ENDPOINTS.CONTACT, data),
    onSuccess(v: any) {

    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });
};
