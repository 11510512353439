import Image from "next/image";
import { getIcon } from "../../lib/get-icon";
import Link from "next/link";
import Container from "@/ui/container";
import { ROUTES } from "@/utils/routes";

function ThreeBanner({ data }: any) {
  const data1: any = [
    {
      img: "/images/home/video1.mp4",
      is_video: true,
      banner_title: "Sensor for 14 days wear time",
    },
    {
      img: "/images/home/video2.mp4",
      is_video: true,
      banner_title: "Reusable transmitter with 2 years of warranty",
    },
    {
      img: "/images/home/video3.mp4",
      is_video: true,
      banner_title:
        "Connect with our curated App for real-time glucose readings, alerts & activity tracking",
    },
    {
      img: "/images/home/video5.mp4",
      is_video: true,
      banner_title: "",
    },
  ];
  const data2: any = [
    {
      img: "/images/home/videomobile1.mp4",
      is_video: true,
      banner_title: "Sensor for 14 days wear time",
    },
    {
      img: "/images/home/videomobile2.mp4",
      is_video: true,
      banner_title: "Reusable transmitter with 2 years of warranty",
    },
    {
      img: "/images/home/videomobile3.mp4",
      is_video: true,
      banner_title:
        "Connect with our curated App for real-time glucose readings, alerts & activity tracking",
    },
    {
      img: "/images/home/videomobile4.mp4",
      is_video: true,
      banner_title: "",
    },
  ];
  return (
    <Container className="bg-white">
      <div className="container 2xl:max-w-[1600px] xl:max-w-[1420px] lg:max-w-[1281px] max-w-[1024px] xs:px-5 py-4 md:pt-[1px] mx-auto xs:py-2 px-1">
        <div className="mt-4 xs:mt-0 mb-2 rounded-0">
          <h1 className="text-[40px] xs:text-[20px] xs:w-full pt-0 xs:pt-4">
            Step into the future of Glucose{" "}
          </h1>
          <h1 className="text-[40px] xs:text-[20px] xs:w-full pb-4">
            Monitoring with <span className="text-[#9765DC]">Tracky CGM</span>
          </h1>
          <div className="grid grid-cols-4 xs:grid-cols-1 gap-10 xs:gap-3 xs:hidden">
            {data1?.[0]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
                <video
                  // controls
                  width={1000}
                  height={1000}
                  autoPlay
                  loop
                  muted
                  className="rounded-[24px] xs:w-[60%] xs:rounded-[7px]"
                  style={{ height: "100%" }}
                >
                  <source src={data1?.[0]?.img} />
                </video>
                <div className="absolute bottom-0 w-full xs:w-[60%] text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                      {data1?.[0]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data1?.[0]?.img && (
                <Link href={"#"} className="">
                  <div className="relative">
                    <Image
                      className="!w-full rounded-[24px] xs:rounded-[7px]"
                      src={data1?.[0]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0  w-full text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                          {data1?.[0]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
            {data1?.[1]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
                <video
                  // controls
                  width={1000}
                  height={1000}
                  autoPlay
                  loop
                  muted
                  className="rounded-[24px] xs:w-[60%] xs:rounded-[7px]"
                  style={{ height: "100%" }}
                >
                  <source src={data1?.[1]?.img} />
                </video>
                <div className="absolute bottom-0 w-full xs:w-[60%] text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500]  text-start leading-normal">
                      {data1?.[1]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data1?.[1]?.img && (
                <Link href={"#"} className="">
                  <div className="relative">
                    <Image
                      className="!w-full rounded-[24px] xs:rounded-[7px]"
                      src={data1?.[1]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0  w-full text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500]  text-start leading-normal">
                          {data1?.[1]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
            {data1?.[2]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
                <video
                  // controls
                  width={1000}
                  height={1000}
                  autoPlay
                  loop
                  muted
                  className="rounded-[24px] xs:w-[60%] xs:rounded-[7px]"
                  style={{ height: "100%" }}
                >
                  <source src={data1?.[2]?.img} />
                </video>
                <div className="absolute bottom-0 w-full xs:w-[60%] text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                      {data1?.[2]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data1?.[2]?.img && (
                <Link href={"#"} className="">
                  <div className="relative xs:flex xs:justify-center xs:items-center">
                    <Image
                      className="!w-full rounded-[24px] xs:!w-[60%] xs:rounded-[7px]"
                      src={data1?.[2]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0 w-full xs:w-[60%] text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                          {data1?.[2]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
            {data1?.[3]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
                <video
                  // controls
                  width={1000}
                  height={1000}
                  autoPlay
                  loop
                  muted
                  className="rounded-[24px] xs:w-[60%] xs:rounded-[7px]"
                  style={{ height: "100%" }}
                >
                  <source src={data1?.[3]?.img} />
                </video>
                <div className="absolute bottom-0 w-full xs:w-[60%] text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                      {data1?.[3]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data1?.[3]?.img && (
                <Link href={"#"} className="">
                  <div className="relative xs:flex xs:justify-center xs:items-center">
                    <Image
                      className="!w-full rounded-[24px] xs:!w-[60%]  xs:rounded-[7px]"
                      src={data1?.[3]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0 w-full xs:w-[60%]  text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                          {data1?.[3]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>
          
          <div className="grid-cols-4 xs:grid-cols-1 gap-10 xs:gap-3 hidden xs:grid">
            {data2?.[0]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
                <video
                // controls
                width={1000}
                height={1000}
                autoPlay
                loop
                muted
                className="rounded-[24px] xs:rounded-[7px]"
                style={{ height: "100%" }}
              >
                <source src={data2?.[0]?.img} />
              </video>
                <div className="absolute bottom-0 w-full xs:w-full text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                      {data2?.[0]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data2?.[0]?.img && (
                <Link href={"#"} className="">
                  <div className="relative">
                    <Image
                      className="!w-full rounded-[24px] xs:rounded-[7px]"
                      src={data2?.[0]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0  w-full text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                          {data2?.[0]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
            {data2?.[1]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
                 <video
                // controls
                width={1000}
                height={1000}
                autoPlay
                loop
                muted
                className="rounded-[24px] xs:rounded-[7px]"
                style={{ height: "100%" }}
              >
                <source src={data2?.[1]?.img} />
              </video>
                <div className="absolute bottom-0 w-full xs:w-full text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500]  text-start leading-normal">
                      {data2?.[1]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data2?.[1]?.img && (
                <Link href={"#"} className="">
                  <div className="relative">
                    <Image
                      className="!w-full rounded-[24px] xs:rounded-[7px]"
                      src={data2?.[1]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0  w-full text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500]  text-start leading-normal">
                          {data2?.[1]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
            {data2?.[2]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
               <video
                // controls
                width={1000}
                height={1000}
                autoPlay
                loop
                muted
                className="rounded-[24px] xs:rounded-[7px]"
                style={{ height: "100%" }}
              >
                <source src={data2?.[2]?.img} />
              </video>
                <div className="absolute bottom-0 w-full xs:w-full text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                      {data2?.[2]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data2?.[2]?.img && (
                <Link href={"#"} className="">
                  <div className="relative xs:flex xs:justify-center xs:items-center">
                    <Image
                      className="!w-full rounded-[24px] xs:!w-[60%] xs:rounded-[7px]"
                      src={data2?.[2]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0 w-full xs:w-full text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                          {data2?.[2]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
            {data2?.[3]?.is_video ? (
              <div className="relative xs:flex xs:justify-center xs:items-center">
               <video
                // controls
                width={1000}
                height={1000}
                autoPlay
                loop
                muted
                className="rounded-[24px] xs:rounded-[7px]"
                style={{ height: "100%" }}
              >
                <source src={data2?.[3]?.img} />
              </video>
                <div className="absolute bottom-0 w-full xs:w-full text-center py-3 xs:pb-0 px-3">
                  <div className="">
                    <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                      {data2?.[3]?.banner_title}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              data2?.[3]?.img && (
                <Link href={"#"} className="">
                  <div className="relative xs:flex xs:justify-center xs:items-center">
                    <Image
                      className="!w-full rounded-[24px] xs:!w-[60%]  xs:rounded-[7px]"
                      src={data2?.[3]?.img}
                      alt=""
                      height={600}
                      width={400}
                    />
                    <div className="absolute bottom-0 w-full xs:w-[60%]  text-center py-3 xs:pb-0 px-3">
                      <div className="">
                        <h2 className="text-[16px] xs:text-[16px] xs:px-5 xs:pb-3 text-[#FFF] font-[500] text-start leading-normal">
                          {data2?.[3]?.banner_title}
                        </h2>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
          </div>
          {/* </Link> */}
        </div>
      </div>
    </Container>
  );
}

export default ThreeBanner;
